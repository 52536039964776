export default {
  registros: [],
  dropdownStatusLicencas: [],
  dropdownTiposLicencas: [],
  registrosEmpresas: [],
  dropdownStatusEmpresa: [],
  dropdownEstados: [],
  dropdownCidadesEmpresa: [],
  dropdownCidades: [],
  dropdownPortesEmpresa: []
}
