export default {
  login: '',
  acessos_usuario: '',
  registrosNotificacoes: [],
  registrosAnexos: [],
  notificacao: {
    ativo: false,
    cor: null,
    icone: null,
    mensagem: null,
    tempo: null
  }
}
