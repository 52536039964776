export default {
  registros: [],
  registrosRma: [],
  registrosLicencas: [],
  dropdownStatusProcesso: [],
  dropdownPorteLicencas: [],
  dropdownStatusLicencas: [],
  dropdownStatusRma: [],
  dropdownTiposLicencas: [],
  registrosEmpresas: [],
  dropdownStatusEmpresa: [],
  dropdownEstados: [],
  dropdownCidadesEmpresa: [],
  dropdownCidades: [],
  dropdownPortesEmpresa: []
}
